#breadcrumbs {
    background-color: #080808;
    position: relative;
    height: 43px;
    &:after {
        content: " ";
        display: block;
        position: absolute;
        background-color: inherit;
        width: 1000px;
        top: 0;
        bottom: 0;
        right: 0;
        margin-right: -1000px;
        z-index: -1;
    }

    .breadcrumbs-menu {
        // margin-left: 1rem;
        // @include media-breakpoint-up(lg) { margin-left: calc(120px + 2.5rem); }
        // @include media-breakpoint-up(xl) { margin-left: calc(120px + 4rem); }
        // @include media-breakpoint-up(xxl) { margin-left: calc(120px + 4.5rem); }

        a, span {
            display: inline-block;
            position: relative;
            font-size: 1rem;
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: 1px;
            text-decoration: none;

            padding: .5rem .5rem .5rem 0;
            @include media-breakpoint-up(lg) { padding: .6rem 1rem; }
        }
        a:first-child(){ padding-left: 0; }

        a { color: darken($nav-link-color, 20%); }
        a:hover, a:active { color: $nav-link-color-active; }
        a:after{
            display: block;
            position: absolute;
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            content: "\f105";
            font-size: 1.1rem;
            color: #404040;
            width: 10px;
            height: 100%;
            top: 0;
            right: -.5rem;
            padding: .6rem 0;
        }
        // a:last-of-type:after {
        //     display: none;
        // }

        i.fas {
            color: #404040;
        }

        color: #808080;
    }
}

@include media-breakpoint-down(xl) {
    body.customize {
        #breadcrumbs .breadcrumbs-menu a:nth-child(1),
        #breadcrumbs .breadcrumbs-menu a:nth-child(2) { 
            display:none;
        }
        
        #breadcrumbs .breadcrumbs-menu a:nth-child(3){
            padding-left: 0;
        }
    }
}