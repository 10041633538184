.hamburger-container {
  position: absolute;
  top: 14px;
  font-size: 0;
}

.hamburger {
  position: relative;
  width: 32px;
  height: 26px;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: 1px solid #333;
  border-radius: 4px;
  box-sizing: content-box;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}
.hamburger.disabled { pointer-events: none; }

.hamburger>div {
  position: absolute;
  top: 6px;
  left: 6px;
  width: 20px;
}

.hamburger span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #ccc;
  border-radius: 2px;
  opacity: 0.5;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}
//.hamburger.open span {
.hamburger.open span {
  border-color: #ccc;
  opacity: 1;
}

.hamburger span:nth-child(1) { top: 0px; }
.hamburger span:nth-child(2),
.hamburger span:nth-child(3) { top: 6px; }
.hamburger span:nth-child(3) { opacity: 0.3; }
.hamburger span:nth-child(4) { top: 12px; }

.hamburger.open span:nth-child(1) { top: 0px; width: 0%; left: 50%; }
.hamburger.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.hamburger.open span:nth-child(3) {
  opacity: 1;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.hamburger.open span:nth-child(4) { top: 16px; width: 0%; left: 50%; }


@include media-breakpoint-up(sm) {
  .hamburger-container { top: 16px; left: 14px; }
}

@include media-breakpoint-up(lg) {
  #menu-header .hamburger  {
    display: none !important;
  }
}
