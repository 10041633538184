#menu-header {
    position: fixed;
    width: 100%;
    top:0;
    z-index: 1000;
    border-bottom: 1px solid #2a2a2a; 
    &>div[class^="container-"]{
        background-color:inherit;
    }

    // &.navbar { padding: 0.5rem 0rem 0.3rem; }
    &.navbar { padding: 0; }

    .navbar-brand { width: 80px; margin: -3px auto 0; padding: 0.7rem 0; }
    li, select { font-size: 19px; }
    .nav-link { padding: .7rem ($nav-padding-x / 1.5); }
    .navbar-collapse { padding-bottom: 0.7rem; }

    @include media-breakpoint-down(lg) { 
        .navbar-collapse:after {
            display: block;
            position: fixed;
            content: ' ';
            background: rgba(0,0,0,1);
            opacity: 0;
            transition: opacity .5s ease;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;
        }
        .navbar-collapse.show:after {
            opacity:1;
        }
    }

    &.navbar-dark .navbar-nav .nav-link { color: $nav-link-color; }
    &.navbar-dark .navbar-nav .nav-link.active,
    &.navbar-dark .navbar-nav .nav-link:hover, 
    &.navbar-dark .navbar-nav .show>.nav-link { color: $nav-link-color-active; }

    form input, form select {
        margin: 0;
    }

    .form--search {
        text-align: right;

        input[type=search] {
            outline: none;
            -webkit-appearance: textfield;
            appearance: textfield;
            -webkit-box-sizing: content-box;
            box-sizing: content-box;
            font-family: inherit;
            font-size: 100%;
        }
        input::-webkit-search-decoration,
        input::-webkit-search-cancel-button {
            display: none; 
        }

        input[type=search] {
            background: url('/img/icons/icon-search-padding.svg') no-repeat -32px center;
            background-origin: content-box;
            color: #f0f0f0;
            border: solid 1px #444;
            padding: 8px 10px 8px 40px;
            width: calc(100% - 54px);
            
            -webkit-transition: all .5s;
            -moz-transition: all .5s;
            transition: all .5s;
        }
        input[type=search]:focus {            
            -webkit-box-shadow: 0 0 5px rgba(109,207,246,.5);
            -moz-box-shadow: 0 0 5px rgba(109,207,246,.5);
            box-shadow: 0 0 5px rgba(109,207,246,.5);
        }

    } /* --end--   .form-search   -------- */

    // input.form-control {
    //     &::placeholder {
    //         //color: rgba($nav-link-color, 0.5);
    //     }
    // }
    &.navbar-dark {
        select, select option {
            background-color: #000;
            color: $nav-link-color;
        }
    }
    select {
        padding: 8px;
        border: solid 1px transparent;
    }

    .form--region-language {

        position: fixed;
        width: 100%;
        bottom: -53px;
        left: 0;
        background-color: black;

        -webkit-transition: bottom .4s;
        -moz-transition: bottom .4s;
        transition: bottom .4s;

        .navbar-nav { flex-direction: row; }
        .navbar-nav li {
            width: 50%;
            display: flex;
            justify-content: center;
        }
        .navbar-nav li select { width: auto; }

        select#select-region {
            //background-image: url('/img/icons/flag-ca.png')!important;
            background-size: contain;
            background-size: 28px;
            background-position: 10px center;
            padding-left: 50px;
        }
        select#select-language {
            width: 90px;
            padding-right: 0;
            background-image: none;
        }

        

    } /* --end--   .form--region-language   -------- */
    #main-menu.show .form--region-language {
        bottom: 0px;
    }

} /* --end--   #menu-header   -------- */

// @keyframes bottom-slide-up {
//   0%   { bottom: -53px; opacity: 0; }
//   100% { bottom: 0px; opacity: 1; }
// }

.fixed-menu-spacer { 
    height: 53px;
    // background-color: red;
    @include media-breakpoint-up(sm) { height: 60px; }
    @include media-breakpoint-up(lg) { height: 70px; }
    @include media-breakpoint-up(xl) { height: 72px; }
}

@include media-breakpoint-up(sm) {
    #menu-header {
        .navbar-brand { width: 100px; }
    }
}
@include media-breakpoint-up(md) {    
}
@include media-breakpoint-up(lg) {
    #menu-header {
        // &.navbar { padding: 0.5rem 1rem 0.3rem; }
        .nav-link { padding: 1.2rem ($nav-padding-x / 1.5)  1.4rem; }
        .navbar-collapse { padding-bottom: 0; }
        .navbar-brand { width: 120px; margin: 0 $nav-padding-x 0 0; padding: 0; }

        .form--search {
            input[type=search] {
                border-color: transparent;
                width: 100px;
            }

            input[type=search]:focus {
                width: 120px;
                border-color: #444;
            }
        }
        .form--region-language {
            position: static;
            width: auto;
            bottom: auto;
            left: auto;
            background-color: transparent;
        }

    }
    
    // html[lang='fr'] body.home h1 { }
}
@include media-breakpoint-up(xl) {
    #menu-header {
        .navbar-brand { width: 120px; margin: 0 ($nav-padding-x * 2) 0 0; }
        li, select { font-size: 21px; }
        .form--search input[type=search]:focus {
            width: 200px;
        }
    }
}
@include media-breakpoint-up(xxl) {
    #menu-header {
        .nav-link { padding: 1.2rem $nav-padding-x 1.4rem; }
        .form--search input[type=search]:focus {
            width: 300px;
        }
    }
}