@font-face {
    font-family: 'LibreBaskerville';
    src: url('../fonts/LibreBaskerville-Regular.woff2') format('woff2'),
         url('../fonts/LibreBaskerville-Regular.woff') format('woff'),
         url('../fonts/LibreBaskerville-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Source Sans Pro';
    src: url('../fonts/SourceSansPro-Regular.woff2') format('woff2'),
         url('../fonts/SourceSansPro-Regular.woff') format('woff'),
         url('../fonts/SourceSansPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro Light';
    src: url('../fonts/SourceSansPro-Light.woff2') format('woff2'),
         url('../fonts/SourceSansPro-Light.woff') format('woff'),
         url('../fonts/SourceSansPro-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
/*
@font-face {
    font-family: 'Source Sans Pro SemiBold';
    src: url('../fonts/SourceSansPro-SemiBold.woff2') format('woff2'),
         url('../fonts/SourceSansPro-SemiBold.woff') format('woff'),
         url('../fonts/SourceSansPro-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Source Sans Pro Bold';
    src: url('../fonts/SourceSansPro-Bold.woff2') format('woff2'),
         url('../fonts/SourceSansPro-Bold.woff') format('woff'),
         url('../fonts/SourceSansPro-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

*/