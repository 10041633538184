footer.footer { 
    position: absolute;
    bottom: 0; width: 100%;
    background-color: #1b1c1c;
    color: #fff;
    
    .logo-nap { max-width: 150px; }
}

.logo-nap img { width: 100%; }
.logo-nap { max-width: 250px; padding: 1.5rem 0; }
.text-center .logo-nap { margin: 0 auto; }