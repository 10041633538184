
form#filter {
    h5 { 
        color: #999;
        font-size: 1.25rem;
        margin-bottom: 1rem;
        @include media-breakpoint-up(lg) { margin-bottom: 2rem; }
    }
    @include media-breakpoint-down(lg) {
        div.row[class*="row--"] {
            display: none;
            &.show { display: flex; }
        }
    }
    & > div.row {
        padding-top: 2rem;
        //padding-bottom: 1rem;
        border-bottom: 1px solid #222;
        @include media-breakpoint-up(lg) { padding-top: 2rem; }

    }
    & > div:nth-of-type(2n) {
        background-color: rgba(255, 255, 255, 0.04);
        background-color: #121212;
    }
    .item.anim {
        opacity: 0;
        margin-top: 50px;
        transition: opacity 0.3s ease;
    }
    .row.buttons-bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        border-top: 1px solid #222;
        background-color: black;
    }
}

body.find, body.selector {
    .form-check {
        display: flex;
        flex-direction: column;
        // align-items: center;
        // justify-content: center;
        padding-left: 0;
        &.image{ min-height: 200px; margin-top: -1rem; }
        // &.image{ min-height: 130px; }
        .img-container {
            display: flex;
            align-items: center;
            min-height: 130px;
            // min-height: 80px;
            margin-top: 0.7rem;
        }
        img {
            width: auto;
            max-width: 90%;
            max-height: 110px;
        }
        // & > div {
        //     //clear: both;
        //     display: flex;
        //     justify-content: center;
        // }
        label { 
            // color: #999;
            // margin-top: 1rem;
            font-size: 1rem;
            margin-bottom: 1rem;
            // padding: 0.5rem 0 0 32px;
            // text-indent: -28px;
            padding: 0.5rem 0 0 0;
            text-indent: 0;
            
            @include media-breakpoint-up(md) {
                font-size: 1.25rem;
                padding-bottom: 0.5em;
                margin-bottom: 2.5rem;
                // padding-left: 40px;
                // text-indent: -38px;
                padding-left: 0;
                text-indent: 0;
            }

            input.form-check-input { 
                width: 20px;
                height: 20px;
                // margin: 0.15em 37px 0 -29px;
                margin: 0.15em 10px 0 0;
                
                background-color: transparent;
                border: 3px solid #666;
                color: #ccc;
                
                &:checked+label { color: #fff; }
                &:checked { border-color: $form-check-input-checked-border-color !important; }
                @include media-breakpoint-up(md) {
                    width: 27px;
                    height: 27px;
                    // margin: 0.1em 50px 0 -40px;           
                    margin: 0.1em 12px 0 0;           
                }
            }
            >br{
                height: 0.6rem;
                display: block;
                content: ' ';
            }
            >span {
                display: block;
                padding-top: 0.7rem;
                line-height: 1.3rem;
                font-size: 1.1rem;
                color: #aaa;
            }
            >span + span {
                padding-top: 0.5rem;
                color: #888;
                line-height: 1.6rem;
            }
        }
    }
}