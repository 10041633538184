body.customize #breadcrumbs {
    position: absolute;
    width: 100%;
    z-index: 1;
}

.roomset-container{
    display: block;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 2050px;
    overflow: hidden;
    img {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        transform: scale(1.4);
        transform-origin: 50% 100%;
        @include media-breakpoint-up(sm) { transform: scale(1.25); }
        @include media-breakpoint-up(lg) { transform: scale(1.15); }
        @include media-breakpoint-up(xl) { transform: scale(1.1); }
        @include media-breakpoint-up(xxl) { transform: scale(1); }
        @include media-breakpoint-down(lg) { margin-bottom: 30px; }     // Allows room for the .mobile-menu-bottom
        @include media-breakpoint-down(sm) { margin-bottom: 0px; }
    }
    .video-container {
        position: absolute;
        //background-color: rgba(255,0,0,0.5);
        top: -50%;
        left: -50%;
        width: 200%;
        height: 200%;
        video {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            min-height: 50%;
            min-width: 50%;
        }
    }
    .camera-buttons {
        position: fixed;
        bottom: 70px;
        margin: 0 0 1.5rem 1.5rem;
        z-index: 1;
        //.hidden { display: none; }
        a + a { margin-right: 1rem; }

        @include media-breakpoint-up(lg) { bottom: 0; }
    }
}

.hidden { display: none; }
.product-title-container {
    display: block;
    position: absolute;
    background-color: rgba(0,0,0,0.7);
    width: 100%;
    top: 0;
    left: 0;
    padding: 1rem 2rem;
    z-index: 1;
    pointer-events: none;
    @include media-breakpoint-up(lg) { top: 43px; }
    & > h1 {
        font-size: 1.4rem;
        letter-spacing: 0.2px;
        text-transform: uppercase;
        margin: 0;
    }
    & > p {
        margin: 0;
        font-size: 1.1rem;
        font-style: italic;
        color: #bbb;
        margin-top: 8px;
        margin-bottom: -3px;
    }
    .instructions {
        display: block;
        position: absolute;
        width: 100%;
        top: 10px;
        left: 0;
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(107,94,83,0.5) 50%, rgba(0,0,0,0) 100%);
        text-shadow: 1px 1px 3px #000;
        padding: 5rem 2rem;
        text-align: center;
        font-size: 1.1rem;
        letter-spacing: 0.4px;
        transition: opacity 1s;
        @include media-breakpoint-up(md) {
            padding: 7rem 2rem;
            font-size: 1.4rem;
            letter-spacing: 0.2px;
        }
        &.hide {
            opacity: 0;
        }
    }
    .instructions > p {
        margin: 0;
    }
}
.product-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 2050px;
    // outline: solid 1px red;
  
    transform: scale(1.4);
    transform-origin: 50% 100%;
    @include media-breakpoint-up(sm) { transform: scale(1.25); }
    @include media-breakpoint-up(lg) { transform: scale(1.15); }
    @include media-breakpoint-up(xl){ transform: scale(1.1); }
    @include media-breakpoint-up(xxl){ transform: scale(1); }
    
    @include media-breakpoint-down(lg) { margin-bottom: 30px; }     // Allows room for the .mobile-menu-bottom
    @include media-breakpoint-down(sm) { margin-bottom: 0px; }
  
    .base-element { margin-bottom: 0%; }
    &>div {
      //margin-bottom: 9.5%;                                          // VERTICAL ADJUST
  
      transform: scale(1);
      transform-origin: 50% 100%;
    }
    .base-element {
        position: relative;
        width: 100%;
        & img {
            position: relative;
            width: 100%;
            margin: 0 auto;
        }
    }
    .element {
        position: absolute;
        top: 0;
        width: 100%;
        & img {
            position: relative;
            width: 100%;
            margin: 0 auto;
        }
  
    }

    .order-0     { z-index: 0;  }
    .order-1     { z-index: 1;  }
    .order-2     { z-index: 2;  }
    .order-3     { z-index: 3;  }
    .order-4     { z-index: 4;  }
    .order-5     { z-index: 5;  }
    .order-6     { z-index: 6;  }
    .order-7     { z-index: 7;  }
    .order-8     { z-index: 8;  }
    .order-9     { z-index: 9;  }
    .order-10    { z-index: 10; }
    .order-11    { z-index: 11; }
    .order-12    { z-index: 12; }
    .order-13    { z-index: 13; }
    .order-14    { z-index: 14; }
    .order-15    { z-index: 15; }
    .order-16    { z-index: 16; }
    .order-17    { z-index: 17; }
    .order-18    { z-index: 18; }
    .order-19    { z-index: 19; }
    .order-20    { z-index: 20; }
  
  }




@media (max-height:1000px) and (min-width: 1750px){ .roomset-container img,  .product-container { bottom: -100px; } }

@media (max-height:925px) and (min-width: 1650px){ .roomset-container img,  .product-container { bottom: -100px; } }
@media (max-height:925px) and (min-width: 1800px){ .roomset-container img,  .product-container { bottom: -150px; } }

@media (max-height:850px) and (min-width: 1420px){ .roomset-container img,  .product-container { bottom: -100px; } }
@media (max-height:850px) and (min-width: 1800px){ .roomset-container img,  .product-container { bottom: -150px; } }

@media (max-height:750px) and (min-width: 1300px){ .roomset-container img,  .product-container { bottom: -100px; } }
@media (max-height:750px) and (min-width: 1600px){ .roomset-container img,  .product-container { bottom: -150px; } }

.mobile-menu-bottom{
    position: fixed;
    opacity: 1;
    bottom: 0;
    left: 0;
    right: 0;
    height: 72px;
    
    background-color: black;
    padding: 1rem 0;
    text-align: center;
    a.btn + a.btn { margin: 0 0 0 .6rem !important; }

    @include media-breakpoint-up(lg) {
        opacity: 0;
        bottom: -72px;
    }
    @media (max-width: 320px){
        a .fas, a .far { display:none; }
    }
}