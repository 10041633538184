@media print {
    body, html { margin-top:0%;
        display:block;
        height:100%;
        overflow: visible !important;
        font-size: 11pt;
    }
    #breadcrumbs, .fixed-menu-spacer, #menu-header, .mobile-menu-bottom { display: none !important; }
    body, .legal, table, .text-light {
        color: #000 !important;
    }
    body.estimate ul.mobile-estimate { display: none !important; }
    body.estimate table.desktop-estimate { display: block !important; }
    body.estimate .product-layers { max-width: 390px; min-height: 300px; }

    body.estimate .desktop-estimate thead tr th {
        font-size: 9pt;
        color: #ddd !important;
    }

    h1, h2, h3, h4, h5 {
        font-size: 14pt !important;
        small { color: #ccc !important; }
    }
    .standard { color: #ccc !important; }
    .mobile-estimate:after {
        content: "";
        display: block;
        clear: both;
    }
    
    .f-l, .f-r { float: none !important; }
}