$options-background-color: #23221f;

section#sidebar {
    width: 350px;
    background-color: #0f0f0f;
    z-index: 2;
    overflow-x: hidden;
    transition: width 1s, left .5s;
    
    &:after {
        content: " ";
        display: block;
        position: absolute;
        background-color: inherit;
        width: 1000px;
        top: 0;
        bottom: 0;
        margin-left: -1000px;
        z-index: -1;
    }
}

.sidebar-container {
    position: fixed;
    height: 100%;
    max-width: 350px;
    ul {
        margin-bottom: 3rem;
        li {
            padding: .5rem 0 .5rem 1rem;
            &.active {
                border-top-left-radius: 7px;
                border-bottom-left-radius: 7px;
                background-color: $options-background-color;
            }
        }
        
        li>span { color: #999;
            & + span { color: #fff; }
        }
        li>a {
            display: block;
            color: #fff;
            text-decoration: none;
        }
        i.far {
            vertical-align: bottom;
            font-size: 1.6rem;
            padding-right: 0.5rem;
            color: #333;
            &.fa-check-circle { color: $danger; }
        }
    }

    .categories-container {
        position: absolute;
        width: 300px;
        height: 100%;
        z-index: 1;
        background-color: #090909;
        li {
            position: relative;
            a {
                display: block;
                &:hover{ cursor: pointer; }
            }
            &[disabled=disabled] {
                pointer-events: none;
                opacity: .4 !important;
            }

            &.option-selected {
                a:before{
                    display: block;
                    position: absolute;
                    content: ' ';
                    width: 3px;
                    height: 3px;
                    background-color: #4f99e3;
                    top: 19px;
                    top: calc(50% - 2px);
                    left: 8px;
                    border-radius: 50%;
                }
            }
        }
    }

    ::-webkit-scrollbar { height: 8px; width: 8px; background: #000; }
    
    ::-webkit-scrollbar-thumb {
        background: #464527;
        -webkit-border-radius: 1ex;
        -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
    }
    
    ::-webkit-scrollbar-corner { background: #000; }

    .options-container {
        position: absolute;
        top: 0;
        left: 70px;
        width: 230px;
        height: 100%;
        padding-bottom: 100px;
        overflow-y: auto;
        scrollbar-width: thin;
        background-color: $options-background-color;
        // z-index: -1;
        transition: left 1s;
        ul { 
            margin-top: 56px;
            @include media-breakpoint-up(sm) { margin-top: 70px; }
            @include media-breakpoint-up(lg) { margin-top: 60px; }
        }
        
        ul li {
            display: none;
            position: relative;
            padding: .5rem 0;
            opacity: 0;
            padding-top: 50px;
        }
        .hamburger-container{
            // display: none;
            left: calc(100% - 50px);
            @include media-breakpoint-up(lg) { 
                // display: block; 
                left: 175px;
            }
        }
        .image-frame {
            display: flex;
            height: 150px;
            overflow: hidden;
            
            background-color: #111;
            border-top: solid 2px rgba(255,255,255,0.24);
            border-bottom: solid 2px rgba(255,255,255,0.18);
            border-left: none;
            border-right: none;
            box-shadow: 0px 30px 50px 50px inset $options-background-color;
            border-top-left-radius: 50px;
            border-bottom-right-radius: 50px;
            opacity: 0.7;
            @include media-breakpoint-up(xl) {
                height: 180px;
            }

            img {
                transform: scale(1.2778);   // scale up 180px image to fill with width  (230px / 180px = 1.27778)
                transform-origin: left center;
                margin: auto 0;
            }
        }
        ul li {
            &.active { display: list-item; }
            &.current {
                .image-frame {
                    border-color: #ffffff;
                    box-shadow: 0 30px 50px 50px inset #192939;
                    background-color: #11406f;
                    opacity: 1;
                }
                .image-frame:after {
                    display: block;
                    position: absolute;
                    font-family: "Font Awesome 5 Free";
                    content: "\f058";
                    // content: "\f00c";
                    // content: "\f192";
                    font-weight: 900;
                    top: 10px;
                    left: 81%;
                    left: calc(95% - 30px);
                    text-indent: -1px;
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    font-size: 32px;
                    color: #CF0A2C;
                    border-radius: 50%;
                    box-shadow: 0 0 0 50px #fff inset;

                    @include media-breakpoint-up(lg) {
                        top: 17px;
                        left: 177px;
                        width: 32px;
                        height: 32px;
                        line-height: 32px;
                        font-size: 36px;
                    }
                }
            }
            &[disabled=disabled] {
                pointer-events: none;
                opacity: .4 !important;
            }
        }

        p { text-align: center; margin: 0; padding-top: .5rem; }
    }
}

section#sidebar.expanded {
    width: 530px;

    .options-container {
        left: 300px;
    }
}

body.customize {
    .sidebar-container {
        position: absolute;
        width: 100%;
    }
}
@include media-breakpoint-down(lg) {
    #main-container>section { width: 100%; }
    section#sidebar {
        display: block;
        position: absolute;
        // top: 0;
        left: -100%;
        width: 100% !important;
        height: 100%;
        &.show-menu{
            left: 0;
        }
    }

    .sidebar-container {

        .categories-container {
            width: 50%;
        }
        
        .options-container {
            width: 50%;
            left: 50% !important;
            ul {
                max-width: 230px;
                margin-left: auto;
                margin-right: auto;
            }
        }
        
    }
}
@include media-breakpoint-down(sm) {
    section#sidebar { 
        .buttons .btn { 
            font-size: 0.8rem;
            padding: .4rem .6rem;
        }
    }
}