//html, body { scroll-behavior: auto !important; }
html, body { height: 100%; overflow-x: hidden; }
body {
    position: relative;
    font-size: 17px;
    // background-color: #32312f;
    background-color: #000;
    color: #fff;
    // @include media-breakpoint-up(sm) { font-size: 17px; }
    @include media-breakpoint-up(lg) { font-size: 18px; }
}
body.light {
    background-color: #fff;
    color: #000; 
}
// * { -webkit-font-smoothing: subpixel-antialiased; }

html[lang='en'] {
    [class*="fr-only"] {
        display: none;
    }
}
html[lang='fr'] {
    .fr-only-inline { display: inline; }
    .fr-only-block { display: block; }
    @include media-breakpoint-down(sm) {
        .fr-small-xxs { font-size: 0.8rem; }
        .btn.fr-small-xxs { padding: .4rem .6rem; }
    }
}

.anchor {
    position: absolute !important;
    overflow: hidden;
    clip: rect(1px,1px,1px,1px);
    width: 1px;
    height: 1px;
    word-wrap: normal;
    margin-top: -72px;
}
.scroll-spacer {
    height: 1000px;
    height: 80vh;
    // background-color: red;
}

.h-100 { 
    height: calc(100vh - 53px - 70px) !important;
    @include media-breakpoint-up(sm) { height: calc(100vh - 53px) !important; }
    @include media-breakpoint-up(md) { height: calc(100vh - 61px) !important; }
    @include media-breakpoint-up(lg) { height: calc(100vh - 72px) !important; }

}

.legal {
    // color: rgba(255,255,255,0.7);
    opacity: 0.7;
    font-style: italic;
    font-size: 0.9rem;
    letter-spacing: .2px;
    line-height: 1.3rem;
    @include media-breakpoint-up(sm) { font-size: 1rem; line-height: 1.4rem; }
}
// .text-light .legal {
//     color: rgba(255,255,255,0.7);
// }

.btn, .btn-group-lg>.btn, .btn-lg {
    border-radius: 2px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.1rem;
}
.btn-group-lg>.btn, .btn-lg { 
    @include media-breakpoint-up(md) {
        padding: 0.8rem 1rem;
    }
    border-width: 2px;
}

.btn-outline-light {
    border-color: rgba(255,255,255,0.5);
}

.btn.btn-dark:hover {
    color: #fff;
    background-color: $danger;
    border-color: $danger;
}

.btn-check:active+.btn-outline-light:focus, .btn-check:checked+.btn-outline-light:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus, .btn-outline-light:active:focus, .btn-outline-light:focus {
    box-shadow: 0 0 0 0.15rem rgba(248,249,250, 0.5);
}
.btn-check:active+.btn-danger, .btn-check:checked+.btn-danger, .btn-danger.active, .btn-danger:active, .show>.btn-danger.dropdown-toggle {
    background-color: $danger!important;
    border-color: $danger!important;
}

.btn-group-sm>.btn, .btn-sm { padding: 0.4rem 0.6rem; }

.btn i.fas, .btn i.far {
    font-size: 0.8rem;    
    &.left { padding-right: 4px; }
}
.fa, .fab, .fad, .fal, .far, .fas { display: inline; }

@include media-breakpoint-up(md) {
    a[href^='tel:'], a.btn[href^='tel:']  { text-decoration: none!important; pointer-events: none; }
}

@include media-breakpoint-down(md) { 
    .buttons{
        .btn + .btn {
            margin-top: 8px;
        }
    }
}

#main-container {
    display: table;
    width: 100%;
    height: 100%;

    &>section { 
        display: table-cell;
        position: relative;
    }
}

section#sidebar {
    width: 300px;
    background-color: #0f0f0f;

    &:after {
        content: " ";
        display: block;
        position: absolute;
        background-color: inherit;
        width: 1000px;
        top: 0;
        bottom: 0;
        margin-left: -1000px;
        z-index: -1;
    }
}

.alert-success, .alert-danger, .alert-warning { color: #ffffff; }
.alert-success {
    background-color: #085231;
    border-color: #37956a;
}
.alert-danger {
    background-color: #561416;
    border-color: #9d373c;
}
.alert-warning {
    background-color: #66350a;
    border-color: #8f5524;
}