
body.estimate {
    padding-bottom: 80px;
    @include media-breakpoint-up(md) { padding-bottom: 0; }

    h1, h2, h3, h4, h5 {
        small {
            color: #888;
            font-size: 0.7em;
        }
    }

    .product-layers {
        max-width: 540px;
        min-height: 390px;
        min-height: 83vw;
        margin: 0 auto;
        position: relative;
        @include media-breakpoint-up(sm) { min-height: 510px; }
        img { position: absolute; width: 100%; }
    }
    
    table.desktop-estimate {
        display: none;
        border-color: #333;
        @include media-breakpoint-up(md) { display: table; }

        thead { 
            font-size: 1rem;
            color: #888;
            text-transform: uppercase;
            letter-spacing: 1.2px;
        }
        thead tr { border-bottom: 2px solid #5c5c5c; }
        tfoot { font-size: 1.2rem; }
        tfoot, tfoot td { border-bottom: none; padding: 1rem .5rem; }
        tfoot tr { border-top: 2px solid #fff; }
    }
        

    ul.mobile-estimate {
        display: block;
        @include media-breakpoint-up(md) { display: none; }

        li { border-bottom: 1px solid #333; padding: .7rem 0; }
        li:after {
            content: "";
            display: block;
            clear: both;
        }
        .name { width: 60%; font-size: 1.1rem; margin-bottom: 0.5rem; }
        .price { width: 40%; text-align: right; padding-right: 0.5rem; }
        .standard .price { letter-spacing: 2px; }
        // .category { font-size: .9rem; }
        .f-l { float: left; }
        .f-r { float: right; }
        .f-n { float: none; }
        .c-l { clear: left; }
        .c-r { clear: right; }
        .c-b { clear: both; }

        .subtotal {
            font-size: 1.2rem;
            border-bottom: none;
            border-top: 2px solid #fff;
        }
    }
    .standard {
        color: rgba(255,255,255,0.55);
        font-style: italic;
    }
    a.btn { margin-right: .6rem }
    a.btn + a.btn {
        margin: .6rem .6rem .6rem 0;
    }
}

body.estimate {
    fieldset {
        border: 1px solid #ddd;
        border-radius: 5px;
        padding-top: 1.3rem;
        position: relative;
        margin: 1rem;
        width: calc(100% - 2rem)!important;
    }
    legend {
        position: absolute;
        top: -1rem;
        background-color: white;
        display: inline-block;
        width: auto;
        padding: 0 0.5rem;
        font-size: 1.2rem;
    }

    .form-switch .form-check-input {
        // background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280,0,0,0.6%29'/%3e%3c/svg%3e");
    }

    .form-control.is-valid, .was-validated .form-control:valid{
        border-color: #93a1ae;
        // background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230a4f9f' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2377c397' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    }
    .form-check-input.is-valid, .was-validated .form-check-input:valid {
        border-color: #93a1ae;
    }
    .form-check-input.is-valid:checked, .was-validated .form-check-input:valid:checked {
        background-color: unset;
    }
    .form-check-input.is-valid:focus, .was-validated .form-check-input:valid:focus {
        box-shadow: 0 0 .2rem .1rem rgba(0, 0, 0, .35);
    }
    .form-check-input.is-valid~.form-check-label, .was-validated .form-check-input:valid~.form-check-label {
        color: inherit;
    }
    .form-control:focus, .form-switch .form-check-input:focus {
        box-shadow: 0 0 .2rem .1rem rgba(0, 0, 0, .35);
    }
    .form-switch .form-check-input:focus {
        // background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280,0,0,0.6%29'/%3e%3c/svg%3e");
    }
    .form-check-input:checked{
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28195,33,39,1%29'/%3e%3c/svg%3e");
        border-color: #c32127;
        border-width: 2px;
    }

    #recipient-dealer {
        max-height: 230px;
        overflow-y: hidden;
        opacity: 0;
        transition: opacity .25s;
        .dealer {
            background-color: #f4f4f6;
            margin: 0 10px 0 0;
            padding: 1.2rem 0;
            border: 1px solid #dedede;
            border-radius: 5px;
            p { margin: 0; }

            &.selected {
                border-color: #a5cbfd;
                background-color: #ffffff;
                box-shadow: 0 0 6px 4px inset #e7e7e7;
            }
        }
        .dealer:nth-child(3) { margin-right: 0; }
        a[href^='tel:'], a.btn[href^='tel:']  { font-size: 1.1rem;}
        a.btn { margin-bottom: 0; }
        .dealer-type-wrapper {
            text-align: center;
            padding-bottom: .9rem;
            font-size: 1rem;
            font-style: italic;
            img {
                display: inline-block;
                width: 16px;
                margin-right: 0.6rem;
                vertical-align: bottom;
            }
        }
        .name {}
        .location { font-size: 1rem; font-style: italic; }
    }


}