
// .bg-home {
//     background: url('/img/dot-grid-6.png') right top repeat, rgb(64,58,52) url('/img/bg-home.jpg') 76.4% center no-repeat;
//     @include media-breakpoint-up(xl) {background-position-x: right, 60%; }
//     @include media-breakpoint-up(xxl) { background-position-x: right, 45.3%; }
// }
body.home { overflow: hidden; }

.dark-bar {
    //background-color: rgba(49,45,47,0.9);
    background-color: rgba(32, 32, 29, 0.88);
    background: linear-gradient(180deg, rgba(32,32,29,0.88) 40%, rgba(32,32,29,0.7) 64%, rgba(32,32,29,0.4) 100%);
    height: 100vh;
    width: 100%;
    max-width: 810px;
    color: white;
    transition: all 1s;
    margin-left: 0;
    overflow-y: auto;
    @include media-breakpoint-up(md) {
        background-image: none;
        background-color: rgba(32, 32, 29, 0.88);
    }
    @include media-breakpoint-up(xxl) { margin-left: 7%; }
    @include media-breakpoint-up(xxxl) { margin-left: 13%; }
}
.dark-bar.animate { overflow: hidden; }

body.home h1 {
    font-family: $font-family-serif;
    padding: 0 0 0.3em 0;
    letter-spacing: 0.6rem;
    text-transform: uppercase;
    line-height: 1.3em;

    @include media-breakpoint-down(sm) {
        font-size: 3.2rem;
        letter-spacing: .4rem;
        line-height: 1.4em;
        text-align: center;
    }
    @media (max-width:480px){
        font-size: 2.4rem;
        letter-spacing: .4rem;
    }
    @include media-breakpoint-between(lg, xxl) {
        font-size: calc(1.875rem + 3vw);
        letter-spacing: .4rem;
        line-height: 1.35em;
    }
    @include media-breakpoint-up(xxl) {
        font-size: 5rem;        
    }
    &:after {
        display: inline;
        content: '//';
        color: #000;
        font-weight: bold;
    }
}
html[lang='fr'] body.home h1 { 
    font-size: calc(1.275rem + 3.9vw); 
    @include media-breakpoint-up(xl) { font-size: 4rem; }
    
}

.dark-bar .col > h2.h5 {
  @include media-breakpoint-only(xs) { font-size: 1.2rem; text-align: center; }
  @include media-breakpoint-down(md) { }
}
.dark-bar .col > h2, 
.dark-bar .col > p.legal {
  transition: all 1s;
}
.home-buttons {
    font-size: 1.1rem;
    text-align: center;
    padding: 2rem 0 1rem;
    a.btn { margin-bottom: 2rem; }
    padding-bottom: 2rem;
    @include media-breakpoint-up(md) { padding-bottom: 3rem; }
  }

.container--fireplace-animation {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    // background: url(/img/dot-grid-6.png) left top repeat, #403a34 url(/img/home-anim/bg-home-2.jpg) 74% 0% no-repeat;
    background: url(/img/dot-grid-6.png) left top repeat, #cfc1b8 url(/img/home-anim/bg-home-lights-on-2.jpg) 74% 30% no-repeat;
    @include media-breakpoint-up(md) { 
        background-position-y: top, 20%;
        background-position-x: left, 77%;
    }
    @include media-breakpoint-up(lg) { background-position-y: top, 50%; }
    @include media-breakpoint-up(xl) { background-position-x: left, 57%; }
    @include media-breakpoint-up(xxl) { background-position-x: left, 45.3%; }
}
.firebox {
    position: absolute;
    // overflow: hidden;
    left: 74%;                                        // must match background-position-x value
    top: 58%;                                         // must match background-position-y value

    width: 755px;
    height: 581px;
    margin-top: calc(581px * -.2 - 13px);
    // margin-left: calc(755px * -.5 - 133px);
    margin-left: calc(755px * -.7 + 39px);
    transform: scale(0.7);
    // background-color: red;
    @include media-breakpoint-up(md) {
      top: 50%;
        left: 77%;                                  // must match background-position-y value
        margin-top: calc(581px * -.36 + 27px);
        margin-left: calc(755px * -.5 - 208px);
        transform: none;
    }
    @include media-breakpoint-up(lg) {
        margin-top: calc(581px * -.5 + 27px);
    }
    @include media-breakpoint-up(xl) { 
        left: 62.4%;                                  // must match background-position-y value
        margin-top: calc(581px * -.5 + 27px);
        margin-left: calc(755px * -.5 + 218px);
    }
    @include media-breakpoint-up(xxl) {
        left: 45.3%;                                  // must match background-position-x value
        margin-left: calc(755px * -.5 + 586px);
    }
}
.firebox .inner {
    position: absolute;
    bottom: 0;
    width: 100%;
    // background-color: green;
    text-align: center;
}
//.firebox img { margin: 0 -50%; }
.firebox .slick-slider { margin: 0; }

@include media-breakpoint-down(xxl) {
    .dark-bar.animate {
        animation: dark-bar-transition 4s;
        animation-fill-mode: forwards;
    }
}
@include media-breakpoint-up(xxl) {
    .dark-bar.animate {
        animation: dark-bar-transition-xxl 4s;
        animation-fill-mode: forwards;
    }
}
@include media-breakpoint-up(xxxl) {
  .dark-bar.animate {
      animation: dark-bar-transition-xxxl 4s;
      animation-fill-mode: forwards;
  }
}
.dark-bar.animate .row,
.dark-bar.animate .col {
    transition: all 1s;
    animation: dark-bar-transition-content 4s;
    animation-fill-mode: forwards;
}

.dark-bar.animate .col h1 {
    opacity: 0;
    animation: slide-right-fade 1s;
    animation-delay: 3.7s;
    animation-fill-mode: forwards;
}
.dark-bar.animate h1:after {
    opacity: 0;    
    animation: fade-in 1s;
    animation-delay: 4.5s;
    animation-fill-mode: forwards;
}
.dark-bar.animate .col h1 span:nth-child(1) {
    opacity: 0;
    animation: slide-right-fade 1s;
    animation-delay: 3.4s;
    animation-fill-mode: forwards;
}
.dark-bar.animate .col h1 span:nth-child(2) {
    opacity: 0;
    animation: slide-right-fade 1s;
    animation-delay: 3.6s;
    animation-fill-mode: forwards;
}
.dark-bar.animate .col h1 span:nth-child(3) {
    opacity: 0;
    animation: slide-right-fade 1s;
    animation-delay: 3.8s;
    animation-fill-mode: forwards;
}


.dark-bar.animate .col > h2 {
  opacity: 0;
  animation: fade-in 1s;
  animation-delay: 5s;
  animation-fill-mode: forwards;
}
.dark-bar.animate .col .home-buttons {
    opacity: 0;
    animation: slide-right-fade .8s;
    animation-delay: 5.5s;
    animation-fill-mode: forwards;
}
.dark-bar.animate .col > p.legal {
  opacity: 0;
  animation: fade-in 1s;
  animation-delay: 6.5s;
  animation-fill-mode: forwards;
}

@keyframes dark-bar-transition {
  0% {
    max-width: 30px;
    margin-left: 0%;
    height: 1vh;
  }
  40% {
    max-width: 30px;
    margin-left: 0%;
    height: 100vh;
  }
  100% {
    max-width: 810px;
    margin-left: 0px;
    height: 100vh;
  }
}
@keyframes dark-bar-transition-xxl {
  0% {
    max-width: 30px;
    margin-left: 7%;
    height: 1vh;
  }
  60% {
    max-width: 30px;
    margin-left: 7%;
    height: 100vh;
  }
  100% {
    max-width: 810px;
    margin-left: 7%;
    height: 100vh;
  }
}
@keyframes dark-bar-transition-xxxl {
  0% {
    max-width: 30px;
    margin-left: 13%;
    height: 1vh;
  }
  60% {
    max-width: 30px;
    margin-left: 13%;
    height: 100vh;
  }
  100% {
    max-width: 810px;
    margin-left: 13%;
    height: 100vh;
  }
}
@keyframes dark-bar-transition-content {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  80% {
    visibility: visible;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}
@keyframes slide-right-fade {
  0% {
    margin-left: -100px;
    margin-right: 100px;
    opacity: 0;
  }
  100% {
    margin-left: 0px;
    margin-right: 0px;
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}